<template>
  <v-app>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper py-8 px-4"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-dialog
          :fullscreen="$vuetify.breakpoint.mobile"
          v-model="mainDialogReturn"
          max-width="50vw"
          min-width="30vw"
          class=""
        >
          <v-card
            class="pa-10 text-h6 text-center"
            :class="{ '': $vuetify.breakpoint.mobile }"
          >
            <v-btn
              color="primary"
              class="mt-n7 "
              absolute
              right
              icon
              @click.prevent="mainDialogData = false"
            >
              <v-icon>
                fas fa-times-circle
              </v-icon>
            </v-btn>
            {{ message }}
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="mobileDialog"
          class=""
          :overlay="true"
          max-width="70vh"
        >
          <v-card class="pa-5 ma-0">
            <validation-observer>
              <v-card-title class="text-center text-h5"
                >Phone link</v-card-title
              >
              <v-row>
                <v-col class="text-center">
                  <div id="recaptcha-container"></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <validation-provider
                    class="ma-0 pa-0"
                    v-slot="{ errors }"
                    name="Phone No"
                    rules="phonerules||required"
                  >
                    <v-text-field
                      outlined
                      width="100%"
                      prefix="91"
                      :error-messages="errors"
                      v-model="phNo"
                      placeholder="Phone Number"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
             
                <v-col cols="3" class="text-center">
                  <v-btn
                    class="buttonGlass"
                    color="primary"
                    large
                    @click="sendOtp()"
                    >Get OTP</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="otp"
                    placeholder="OTP"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="text-end">
                  <v-btn
                    class="buttonGlass"
                    color="primary"
                    :disabled="!getOtp"
                    large
                    @click="verifyOtp()"
                    >Verify OTP</v-btn
                  >
                </v-col>
              </v-row>

              <div class="text-center">
                <v-btn @click="sendOtp()" class="buttonGlass white--text" :disabled="resendOtp" color="red"
                  >Resend OTP</v-btn
                >
              </div>
            </validation-observer>
          </v-card>
        </v-dialog>
        <v-card class="mt-n4 px-7 py-4" elevation="6">
          <v-row class="my-2">
            <v-col
              ><div style="display:inline;z-index:5" class="text-h4 ma-0 pa-0">
                User Profiles Settings
              </div></v-col
            >
            <v-col class="text-end">
              <v-btn
                color="primary"
                class="mx-2 my-1 buttonGlass"
                @click="phoneLink"
                v-if="!providerData.includes('phone')"
                >Link My Phone No</v-btn
              >
              <v-btn
                color="primary"
                class="mx-2 my-1 buttonGlass"
                @click="googleLink"
                v-if="!providerData.includes('google.com')"
                >Link with Google</v-btn
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <validation-observer ref="form" v-slot="{ invalid }">
            <v-form v-model="formHasErrors">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="fas fa-user"
                      outlined
                      v-model="userInfo.name"
                      readonly
                      :label="nameLabel"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="fas fa-university"
                      outlined
                      v-model="userInfo.nulm"
                      readonly
                      label="NULM"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col v-if="userInfo.department" cols="12" md="4">
                    <v-text-field
                      prepend-icon="fas fa-landmark"
                      outlined
                      v-model="userInfo.department"
                      readonly
                      label="Department"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="fas fa-map-marker"
                      outlined
                      v-model="userInfo.district"
                      readonly
                      label="District"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      class="ma-0 pa-0"
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        ref="Address"
                        :error-messages="errors"
                        v-model="address"
                        label="Address"
                        hint="Enter Your Address"
                        prepend-icon="fas fa-map-marker-alt"
                        @change="checkForUpdate('address', address)"
                      ></v-text-field
                    ></validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      class="ma-0 pa-0"
                      v-slot="{ errors }"
                      name="Phone No"
                      rules="phonerules||required"
                    >
                      <v-text-field
                        outlined
                        ref="Phone No"
                        :error-messages="errors"
                        v-model="phone"
                        label="Phone No"
                        hint="Enter Your Phone No"
                        prepend-icon="fas fa-phone"
                        prefix="91"
                        @change="checkForUpdate('phone', phone)"
                      ></v-text-field
                    ></validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      class="ma-0 pa-0"
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        outlined
                        :error-messages="errors"
                        v-model="email"
                        autocomplete="off"
                        @change="checkForUpdate('email', email)"
                        label="E-mail"
                        name="email"
                        prepend-icon="fas fa-envelope"
                        type="text"
                        color="primary accent-3"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div
                  v-if="
                    userInfo.department === 'ALF' ||
                      userInfo.department === 'CLF' ||
                      userInfo.department === 'SHG'
                  "
                >
                  <v-row>
                    <v-col class="cols-last">
                      <validation-provider
                        name="Group Dinhmun"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :error-messages="errors"
                          label="Group Status"
                          :items="['Kehdarh', 'Nung Tha Mek']"
                          v-model="groupStatus"
                          prepend-icon="fas fa-users-cog"
                          @change="checkForUpdate('groupStatus', groupStatus)"
                        ></v-select
                      ></validation-provider>
                    </v-col>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Group/Federation Code"
                      >
                        <v-text-field
                          outlined
                          :error-messages="errors"
                          label="Group/Federation Code"
                          name="code"
                          hints="Enter your group or federation code"
                          v-model="code"
                          prepend-icon="fas fa-id-card"
                          @change="checkForUpdate('code', code)"
                        >
                        </v-text-field> </validation-provider
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="cols-last">
                      <validation-provider
                        name="Group Funds Dawn leh Dawnloh"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :error-messages="errors"
                          label="Group Funds"
                          :items="['Dawng', 'Dawnglo']"
                          v-model="fundsRecieved"
                          prepend-icon="fa-rupee-sign"
                          @change="
                            checkForUpdate('fundsRecieved', fundsRecieved)
                          "
                        ></v-select
                      ></validation-provider>
                    </v-col>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required||numeric"
                        name="Group Member awm zat"
                      >
                        <v-text-field
                          outlined
                          :error-messages="errors"
                          label="Groupa Member awm zat"
                          name="code"
                          hints="Enter amount of members in your group"
                          v-model="members"
                          prepend-icon="fas fa-users"
                          @change="checkForUpdate('members', members)"
                        >
                        </v-text-field> </validation-provider
                    ></v-col>
                  </v-row>
                </div>

                <div v-if="typeof userInfo.fundReceived === 'object'">
                  <div class="text-center text-h5 my-3">
                    Financial Report(Funds Received)
                  </div>
                  <v-data-table
                    :headers="headers"
                    :items="funds"
                    hide-default-footer
                    class="elevation-1"
                  >
                  </v-data-table>
                </div>
              </v-card-text>
              <v-divider class=""></v-divider>

              <div class="text-center mt-4 mx-4">
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required: false, password: 6 }"
                      name="Password thar tur"
                      ><v-text-field
                        @change="checkForUpdate('password', password)"
                        outlined
                        :error-messages="errors"
                        label="Password thlak duh tan tah hian thlak tur a ni"
                        name="code"
                        hint="Password thlak duhlo tan dah awl mai tur a ni"
                        v-model="password"
                        prepend-icon="fas fa-lock"
                        >New Password</v-text-field
                      ></validation-provider
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      class="px-5 my-4 buttonGlass"
                      color="blue white--text"
                      @click.prevent="submit"
                      :disabled="!checkButton(invalid)"
                    >
                      Confirm
                    </v-btn></v-col
                  >
                </v-row>
              </div>
            </v-form>
          </validation-observer>
        </v-card>
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import firebase from 'firebase/app'
import { mapState } from 'vuex'
import 'firebase/auth'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
import 'firebase/firestore'
import {
  min,
  required,
  email,
  // alpha_spaces,
  numeric
} from 'vee-validate/dist/rules'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} a ruak theilo'
})
extend('numeric', {
  ...numeric,
  message: '{_field_} zat hi thumal niloin a number-in ziah tur'
})
extend(
  'phonerules',
  value => {
    return value < 10000000000 && value > 999999999
  },
  { message: 'Phone Number hi digit sawm tur a ni' }
)
extend('password', {
  ...min,
  message: '{_field_} hi paruk tal angai'
})

extend('email', {
  ...email,
  message: 'Email diktak hman tur'
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      mobileDialog: false,
      providerData: [],
      phNo: '',
      appVerifier: '',
      otp: '',
      mainDialogReturn: false,
      message: '',
      headers: [
        {
          text: 'Year',
          align: 'start',
          value: 'year'
        },
        {
          text: 'Amount',
          align: 'start',
          value: 'amount'
        }
      ],
      updateNeeded: {},
      createdAt: '',
      msg: '',
      address: '',
      groupStatus: '',
      phone: '',
      err: false,
      email: '',
      members: '',
      code: '',
      fundsRecieved: '',
      group: null,
      password: '',
      formHasErrors: '',
      District: [
        'Aizawl',
        'Kolasib',
        'Lawngtlai',
        'Lunglei',
        'Mamit',
        'Saiha ',
        'Serchhip',
        'Champhai',
        'Hnahthial',
        'Khawzawl',
        'Saitual'
      ],
      nulmItem: [
        'Social Mobilisation & Institutional Development (SM & ID)',
        'Employment through Skill Training and Placement(EST&P)',
        'Self-Employment Programme (SEP)',
        'Support to Urban Street Vendors (SUSV)',
        'Scheme of Shelter For Urban Homeless (SUH)'
      ],
      authItem: [
        'Regular',
        'Group Admin',
        'District Manager',
        'RO',
        'UD&PA Admin'
      ],
      getOtp: false,
      resendOtp:false,
      errorMessages: ''
    }
  },
  methods: {
    sendOtp () {
      if (this.phNo.length != 10) {
        alert('Invalid Phone Number Format !')
      } else {
        if (this.getOtp===true) this.resendOtp=true
        let countryCode = '+91' //india
        let phoneNumber = countryCode + this.phNo
        //
        let appVerifier = this.appVerifier
        //
        firebase
          .auth()
          .currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
          .then( (confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult,
            //
            this.getOtp = true,
            alert('SMS sent')
            
          })
          .catch(function (error) {
            // Error; SMS not sent
            // ...
            alert(error, 'Error ! SMS not sent')
          })
      }
    },
    verifyOtp () {
      if (this.phNo.length != 10 || this.otp.length != 6) {
        alert('Invalid Phone Number/OTP Format !')
      } else {
        //
        // let vm = this;
        let code = this.otp
        //
        window.confirmationResult
          .confirm(code)
          .then(function (result) {
            // User signed in successfully.
            var user = result.user
            // ...
            user
            //route to set password !
            window.alert('User Linked phone Number')
          })
          .catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            alert(error)
          })
      }
    },
    initReCaptcha () {
      setTimeout(() => {
        // let vm = this;
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: function () {
            },
            'expired-callback': function () {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          }
        )
        //
        this.appVerifier = window.recaptchaVerifier
      }, 1000)
    },
    async googleLink () {
      this.mainDialogReturn = true
      this.message = 'Confirm your google authentication'
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'en'

      await firebase
        .auth()
        .currentUser.linkWithPopup(provider)
        .then(result => {
          window.alert('Account linked successfully')
          var credential = result.credential
          credential
          var user = result.user
         user
          // ...
        })
        .catch(error => {
          window.alert(error)
          // Handle Errors here.
          // ...
        })
    },
    async phoneLink () {
      this.initReCaptcha()
      this.mobileDialog = true
    },

    async logOut () {
      firebase.auth.PhoneAuthProvider
      this.msg = 'You need to relogin to perform this operations'
      this.mainDialogRet = true
    },
    checkForUpdate (type, input) {
      if (input != this.userInfo[type]) {
        this.updateNeeded[type] = input
      } else if (this.updateNeeded[type]) {
        delete this.updateNeeded[type]
      }
    },
    async submit () {
      this.$store.commit('changeMainDialog', true)
      this.$store.commit(
        'changeMainDialogMessage',
        'Updating you account please wait'
      )
      if (this.updateNeeded['password']) {
        firebase
          .auth()
          .currentUser.updatePassword(this.password)
          .then(async () => {
            delete this.updateNeeded['password']
          })
          .catch(async err => {
            this.err = true
            if (err.code === 'auth/requires-recent-login') {
              this.logOut()
            }
            this.$store.commit('changeError', err.code)
          })
      }
      if (this.updateNeeded['email']) {
        await firebase
          .auth()
          .currentUser.updateEmail(this.email)
          .catch(async err => {
            this.err = true
            if (err.code === 'auth/requires-recent-login') {
              this.logOut()
            }
            this.$store.commit('changeError', err.code)
          })
      }

      let col
      if (this.updateNeeded['phone'] != undefined) {
        //  let col
        if (['SMMU', 'CMMU'].includes(this.userInfo.nulm)) {
          col = this.userInfo.nulm.toLowerCase().replace('&', 'n')
        } else if (['ESTP', 'SUSV', 'SEP'].includes(this.userInfo.nulm)) {
          col = this.userInfo.nulm
            .toLowerCase()
            .replace('&', 'n')
            .concat('_', this.userInfo.district.toLowerCase())
        } else if (
          ['SHG', 'ALF', 'CLF', 'SMA', 'SMC'].includes(this.userInfo.department)
        ) {
          col = this.userInfo.department
            ?.toLowerCase()
            .concat('_', this.userInfo.district.toLowerCase())
        }
        let contact = {
          firstName: this.userInfo.name,
          lastName: this.userInfo.id,
          number: parseInt(`91${this.updateNeeded['phone']}`),
          custom1: col,
          custom2: this.updateNeeded['phone']
        }

        await firebase
          .firestore()
          .collection('counter')
          .doc('phoneUpdateNeeded')
          .update({
            updateNeeded: firebase.firestore.FieldValue.arrayUnion(contact)
          })
          .catch(async err => {
            this.err = false
            if (err.code === 'auth/requires-recent-login') {
              this.logOut()
            }
            this.$store.commit('changeError', err.code)
          })
      }
      this.changeUser()
    },
    async changeUser () {
      if (!this.err)
        await firebase
          .firestore()
          .collection(localStorage.getItem('userDb'))
          .doc(this.userInfo.id)
          .update(this.updateNeeded)
          .then(async () => {
            this.$store.commit('changeMainDialog', false)
          })
          .catch(err => this.$store.commit('changeError', err))
    },
    checkButton (input) {
      if (input) return false
      else if (!input) {
        return Object.keys(this.updateNeeded).length > 0
      }
    },
    updateEmail () {
      var user = firebase.auth().currentUser

      user
        .updateEmail(this.email)
        .then(function () {
          firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth().currentUser.uid)
            .update({
              email: firebase.auth().currentUser.email
            })
            .then(window.alert('Email Update Successful'))
        })
        .catch(function (error) {
          this.$store.commit('changeError', error)
        })
    }
  },
  created () {
    let user = firebase.auth().currentUser.toJSON()
    user.providerData.forEach(e => this.providerData.push(e.providerId))
  },
  computed: {
    nameLabel(){
      if(this.userInfo.department){
        return `Name of ${this.userInfo.department}`
        }
        else return `Name of ${this.userInfo.nulm}`
      
    },
    funds () {
      let arr = []
      for (let property in this.userInfo.fundReceived) {
        arr.push({
          year: property,
          amount: this.userInfo.fundReceived[property]
        })
      }
      return arr
    },
    ...mapState(['userInfo'])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.phone = vm.$store.state.userInfo.phone
      vm.email = vm.$store.state.userInfo.email
      vm.address = vm.$store.state.userInfo.address
      vm.code = vm.$store.state.userInfo.code
      vm.createdAt = vm.$store.state.userInfo.createdAt
      vm.fundsRecieved = vm.$store.state.userInfo.fundsRecieved
      vm.groupStatus = vm.$store.state.userInfo.groupStatus
      vm.members = vm.$store.state.userInfo.members
    })
  }
}
</script>
<style scoped>
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
#header {
  z-index: 5 !important;
}
#header:before {
  position: absolute;
  right: 0;
  content: '';
  z-index: 0;
  height: 1px;
  background: rgb(110, 106, 106);
  width: 59vw;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  box-shadow: #07070748;
}
.bgSvg {
  min-height: 100vh;

  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.reportCard {
  min-height: 100vh;
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
</style>
