var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{class:{
      bgSvg: !_vm.$vuetify.theme.dark,
      bgSvgDark: _vm.$vuetify.theme.dark
    }},[_c('v-flex',{staticClass:"backDropper py-8 px-4",style:({
        'color:rgb(255, 255, 255,0.5)': !_vm.$vuetify.theme.dark,
        'color:rgb(0,0,0)': _vm.$vuetify.theme.dark
      })},[_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"50vw","min-width":"30vw"},model:{value:(_vm.mainDialogReturn),callback:function ($$v) {_vm.mainDialogReturn=$$v},expression:"mainDialogReturn"}},[_c('v-card',{staticClass:"pa-10 text-h6 text-center",class:{ '': _vm.$vuetify.breakpoint.mobile }},[_c('v-btn',{staticClass:"mt-n7 ",attrs:{"color":"primary","absolute":"","right":"","icon":""},on:{"click":function($event){$event.preventDefault();_vm.mainDialogData = false}}},[_c('v-icon',[_vm._v(" fas fa-times-circle ")])],1),_vm._v(" "+_vm._s(_vm.message)+" ")],1)],1),_c('v-dialog',{attrs:{"overlay":true,"max-width":"70vh"},model:{value:(_vm.mobileDialog),callback:function ($$v) {_vm.mobileDialog=$$v},expression:"mobileDialog"}},[_c('v-card',{staticClass:"pa-5 ma-0"},[_c('validation-observer',[_c('v-card-title',{staticClass:"text-center text-h5"},[_vm._v("Phone link")]),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('div',{attrs:{"id":"recaptcha-container"}})])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{staticClass:"ma-0 pa-0",attrs:{"name":"Phone No","rules":"phonerules||required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","width":"100%","prefix":"91","error-messages":errors,"placeholder":"Phone Number"},model:{value:(_vm.phNo),callback:function ($$v) {_vm.phNo=$$v},expression:"phNo"}})]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"buttonGlass",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.sendOtp()}}},[_vm._v("Get OTP")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"placeholder":"OTP","outlined":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"buttonGlass",attrs:{"color":"primary","disabled":!_vm.getOtp,"large":""},on:{"click":function($event){return _vm.verifyOtp()}}},[_vm._v("Verify OTP")])],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"buttonGlass white--text",attrs:{"disabled":_vm.resendOtp,"color":"red"},on:{"click":function($event){return _vm.sendOtp()}}},[_vm._v("Resend OTP")])],1)],1)],1)],1),_c('v-card',{staticClass:"mt-n4 px-7 py-4",attrs:{"elevation":"6"}},[_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('div',{staticClass:"text-h4 ma-0 pa-0",staticStyle:{"display":"inline","z-index":"5"}},[_vm._v(" User Profiles Settings ")])]),_c('v-col',{staticClass:"text-end"},[(!_vm.providerData.includes('phone'))?_c('v-btn',{staticClass:"mx-2 my-1 buttonGlass",attrs:{"color":"primary"},on:{"click":_vm.phoneLink}},[_vm._v("Link My Phone No")]):_vm._e(),(!_vm.providerData.includes('google.com'))?_c('v-btn',{staticClass:"mx-2 my-1 buttonGlass",attrs:{"color":"primary"},on:{"click":_vm.googleLink}},[_vm._v("Link with Google")]):_vm._e()],1)],1),_c('v-divider'),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('v-form',{model:{value:(_vm.formHasErrors),callback:function ($$v) {_vm.formHasErrors=$$v},expression:"formHasErrors"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"fas fa-user","outlined":"","readonly":"","label":_vm.nameLabel},model:{value:(_vm.userInfo.name),callback:function ($$v) {_vm.$set(_vm.userInfo, "name", $$v)},expression:"userInfo.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"fas fa-university","outlined":"","readonly":"","label":"NULM"},model:{value:(_vm.userInfo.nulm),callback:function ($$v) {_vm.$set(_vm.userInfo, "nulm", $$v)},expression:"userInfo.nulm"}})],1),(_vm.userInfo.department)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"fas fa-landmark","outlined":"","readonly":"","label":"Department"},model:{value:(_vm.userInfo.department),callback:function ($$v) {_vm.$set(_vm.userInfo, "department", $$v)},expression:"userInfo.department"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"fas fa-map-marker","outlined":"","readonly":"","label":"District"},model:{value:(_vm.userInfo.district),callback:function ($$v) {_vm.$set(_vm.userInfo, "district", $$v)},expression:"userInfo.district"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"ma-0 pa-0",attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{ref:"Address",attrs:{"outlined":"","error-messages":errors,"label":"Address","hint":"Enter Your Address","prepend-icon":"fas fa-map-marker-alt"},on:{"change":function($event){return _vm.checkForUpdate('address', _vm.address)}},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"ma-0 pa-0",attrs:{"name":"Phone No","rules":"phonerules||required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{ref:"Phone No",attrs:{"outlined":"","error-messages":errors,"label":"Phone No","hint":"Enter Your Phone No","prepend-icon":"fas fa-phone","prefix":"91"},on:{"change":function($event){return _vm.checkForUpdate('phone', _vm.phone)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"ma-0 pa-0",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"autocomplete":"off","label":"E-mail","name":"email","prepend-icon":"fas fa-envelope","type":"text","color":"primary accent-3"},on:{"change":function($event){return _vm.checkForUpdate('email', _vm.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),(
                  _vm.userInfo.department === 'ALF' ||
                    _vm.userInfo.department === 'CLF' ||
                    _vm.userInfo.department === 'SHG'
                )?_c('div',[_c('v-row',[_c('v-col',{staticClass:"cols-last"},[_c('validation-provider',{attrs:{"name":"Group Dinhmun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","error-messages":errors,"label":"Group Status","items":['Kehdarh', 'Nung Tha Mek'],"prepend-icon":"fas fa-users-cog"},on:{"change":function($event){return _vm.checkForUpdate('groupStatus', _vm.groupStatus)}},model:{value:(_vm.groupStatus),callback:function ($$v) {_vm.groupStatus=$$v},expression:"groupStatus"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Group/Federation Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Group/Federation Code","name":"code","hints":"Enter your group or federation code","prepend-icon":"fas fa-id-card"},on:{"change":function($event){return _vm.checkForUpdate('code', _vm.code)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"cols-last"},[_c('validation-provider',{attrs:{"name":"Group Funds Dawn leh Dawnloh","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","error-messages":errors,"label":"Group Funds","items":['Dawng', 'Dawnglo'],"prepend-icon":"fa-rupee-sign"},on:{"change":function($event){return _vm.checkForUpdate('fundsRecieved', _vm.fundsRecieved)}},model:{value:(_vm.fundsRecieved),callback:function ($$v) {_vm.fundsRecieved=$$v},expression:"fundsRecieved"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required||numeric","name":"Group Member awm zat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Groupa Member awm zat","name":"code","hints":"Enter amount of members in your group","prepend-icon":"fas fa-users"},on:{"change":function($event){return _vm.checkForUpdate('members', _vm.members)}},model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}})]}}],null,true)})],1)],1)],1):_vm._e(),(typeof _vm.userInfo.fundReceived === 'object')?_c('div',[_c('div',{staticClass:"text-center text-h5 my-3"},[_vm._v(" Financial Report(Funds Received) ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.funds,"hide-default-footer":""}})],1):_vm._e()],1),_c('v-divider',{}),_c('div',{staticClass:"text-center mt-4 mx-4"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":{ required: false, password: 6 },"name":"Password thar tur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Password thlak duh tan tah hian thlak tur a ni","name":"code","hint":"Password thlak duhlo tan dah awl mai tur a ni","prepend-icon":"fas fa-lock"},on:{"change":function($event){return _vm.checkForUpdate('password', _vm.password)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_vm._v("New Password")])]}}],null,true)})],1),_c('v-col',[_c('v-btn',{staticClass:"px-5 my-4 buttonGlass",attrs:{"color":"blue white--text","disabled":!_vm.checkButton(invalid)},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }